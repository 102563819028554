import * as React from "react";

import "normalize.css";

import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import ContactIcon from "../components/ContactIcon";
import Container from "../components/Container";

const page404: React.FC = () => (
  <LayoutRoot>
    <Header title="404" />
    <LayoutMain>
      <Container>
        <h1>404 - Seite nicht gefunden</h1>
        <p>
          Klicken Sie <a href="/">hier</a> um zur Startseite zurückzukehren.
        </p>
      </Container>
    </LayoutMain>
    <Footer />
    <ContactIcon />
  </LayoutRoot>
);

export default page404;
